import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

export const Introduction = () => {
  return (
    <Box my="2em">
      <Typography variant="h4" mt="4em" mb="0.5em" sx={{ fontWeight: 700 }}>
        Hello, I'm Brandon - A Software Engineer based in Colorado.
      </Typography>
      <Typography variant="body1">
        I've developed features for millions of users on two of Fidelity's
        applications, created UI / UX across the Pendo platform, and currently
        building out Micro Frontends and services at Storable.
      </Typography>
      <Box mt="1em">
        <Link
          href="mailto:jonesbrandonsean@gmail.com"
          sx={{
            color: "black",
            padding: "2px",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
        >
          jonesbrandonsean@gmail.com
        </Link>
      </Box>
    </Box>
  );
};
